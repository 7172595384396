const html = document.getElementsByTagName("html")[0];

(function changeContrast() {
  const contrastButtons = document.querySelectorAll(".js-contrast-button");

  if (!contrastButtons) {
    return;
  }

  Array.from(contrastButtons).forEach((contrastButton) =>
    contrastButton.addEventListener("click", function () {
      const contrastClass = contrastButton.getAttribute("id");
      html.className = "";
      html.classList.add(contrastClass);
    }),
  );
})();

(function resetContrast() {
  const resetContrastButton = document.querySelector(
    ".js-reset-contrast-button",
  );

  resetContrastButton.addEventListener("click", function () {
    html.className = "";
  });
})();
