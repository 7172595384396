import tippy from "tippy.js";
import _debounce from "lodash.debounce";

function menuSubmenus() {
  const dropdownTriggers = document.querySelectorAll(
    ".js-submenu-dropdown-trigger",
  );

  const createTippy = (trigger) => {
    const content = trigger.parentNode.querySelector(".js-submenu").outerHTML;

    return tippy(trigger, {
      appendTo: () => document.body,
      allowHTML: true,
      content,
      interactive: true,
      arrow: true,
      offset: [0, -6],
      placement: "bottom",
      zIndex: 110,
      maxWidth: 300,
    });
  };

  let tippyInstances = [];

  const handleTippyDropdowns = () => {
    tippyInstances.forEach((instance) => {
      instance.destroy();
    });
    tippyInstances = [];

    if (window.innerWidth > 768) {
      dropdownTriggers.forEach((dropdownTrigger) => {
        tippyInstances.push(createTippy(dropdownTrigger));
      });
    }
  };

  handleTippyDropdowns();

  const handleResize = _debounce(handleTippyDropdowns, 200);
  window.addEventListener("resize", handleResize);
}

menuSubmenus();
