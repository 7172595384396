function mobileMenu() {
  const mobileMenuIcon = document.querySelector(".js-mobile-menu-icon");
  const mobileMenuCloseIcon = document.querySelector(
    ".js-mobile-menu-close-icon",
  );
  const mobileMenu = document.querySelector(".js-mobile-menu-container");
  const mobileMenuOverlay = document.querySelector(".js-mobile-menu-overlay");
  const mobileClosingElements = [mobileMenuCloseIcon, mobileMenuOverlay];

  if (mobileMenuIcon) {
    mobileMenuIcon.addEventListener("click", function () {
      mobileMenu.classList.add("open");
      mobileMenuOverlay.classList.add("open");
      if (mobileMenu.classList.contains("open")) {
        mobileClosingElements.forEach(function (e) {
          e.addEventListener("click", function () {
            mobileMenu.classList.remove("open");
            mobileMenuOverlay.classList.remove("open");
          });
        });
      }
    });
  }
}

mobileMenu();
