function backToTop() {
  const backToTopButton = document.getElementById("js-scroll-top-link");

  if (!backToTopButton) {
    return;
  }

  backToTopButton.addEventListener("click", function () {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  });
}

backToTop();
