import Siema from "siema";

if (document.querySelector(".js-siema-1-slide")) {
  const mySiema = new Siema({
    loop: true,
    easing: "cubic-bezier(0.33, 1, 0.68, 1)",
    selector: ".js-siema-1-slide",
  });

  const prev = document.querySelectorAll(".js-carousel__prev");
  const next = document.querySelectorAll(".js-carousel__next");

  prev.forEach(function (e) {
    e.addEventListener("click", () => {
      mySiema.prev();
    });
  });
  next.forEach(function (e) {
    e.addEventListener("click", () => {
      mySiema.next();
    });
  });
}
