function expand() {
  const dropdownTriggers = document.querySelectorAll(".js-dropdown-trigger");

  Array.from(dropdownTriggers).forEach((dropdownTrigger) =>
    dropdownTrigger.addEventListener("click", function () {
      this.parentNode.classList.toggle("js-dropdown-visible");
    }),
  );
}

expand();
